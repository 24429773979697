
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ---------------------------------------------------
    Login STYLE
----------------------------------------------------- */
.center_v {
  display: flex;
  align-items: center;
  justify-content: center;
}
.main_card {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.login_card {
  padding: 15px 30px;
}
.login_form {
  width: 500px;
  max-width: 500px;
  min-width: 300px;
}
.login_form .card {
  padding: 18px;
  border-radius: 15px;
}
.bg-w {
  background-color: #fff!important;
}
.w-40{
  width: 40% !important;
}